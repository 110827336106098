import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaSpotify, FaApple, FaDeezer } from "react-icons/fa";
import MyImage from "../reactLazyLoading";

type Track = {
  number: number;
  title: string;
};

type Single = {
  id: string;
  Titre: string;
  Année: number;
  Pochette: string;
  Track: Track;
  spotifyLink?: string;
  appleMusicLink?: string;
  deezerLink?: string;
};

type SinglesSectionProps = {
  singlesData: Single[];
};

const SinglesSection: React.FC<SinglesSectionProps> = ({ singlesData }) => {
  const [selectedSingleId, setSelectedSingleId] = useState<string | null>(null);

  const handleSelectSingle = (singleId: string) => {
    setSelectedSingleId(singleId);
    document.body.style.overflow = "hidden";
  };

  const closePopup = () => {
    setSelectedSingleId(null);
    document.body.style.overflow = "auto";
  };

  const selectedSingle = singlesData.find(
    (single) => single.id === selectedSingleId
  );

  return (
    <div className="album-discography">
      {singlesData.map((single) => (
        <motion.div
          key={single.id}
          layoutId={single.id}
          onClick={() => handleSelectSingle(single.id)}
          className="album"
          whileHover={{ scale: 1.05 }}
        >
          <MyImage
            src={single.Pochette}
            height="100%"
            width="100%"
            className=""
          />
          <p className="Atriste-p">{single.Titre}</p>
          <p className="Atriste-p" id="Annee">
            {single.Année}
          </p>
        </motion.div>
      ))}

      <AnimatePresence>
        {selectedSingle && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={closePopup}
          >
            <motion.div
              layoutId={selectedSingle.id}
              className="modal-content"
              onClick={(e) => e.stopPropagation()}
            >
              <button className="Fermer-modal" onClick={closePopup}>
                <span></span>
              </button>

              <MyImage
                src={selectedSingle.Pochette}
                height="100%"
                width="100%"
                className=""
              />
              <div className="Atriste-p-container">
                <p className="Atriste-p">{selectedSingle.Titre}</p>
                <p className="Atriste-p" id="Annee">
                  {selectedSingle.Année}
                </p>
              </div>

              {/* Lien d'écoute pour les plateformes */}
              <div className="Modal-plateform">
                {selectedSingle.spotifyLink && (
                  <a
                    href={selectedSingle.spotifyLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Spotify"
                  >
                    <FaSpotify style={{ height: "2em", width: "2em" }} />
                  </a>
                )}
                {selectedSingle.appleMusicLink && (
                  <a
                    href={selectedSingle.appleMusicLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Apple Music"
                  >
                    <FaApple style={{ height: "2em", width: "2em" }} />
                  </a>
                )}
                {selectedSingle.deezerLink && (
                  <a
                    href={selectedSingle.deezerLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Deezer"
                  >
                    <FaDeezer style={{ height: "2em", width: "2em" }} />
                  </a>
                )}
              </div>

              {/* Affichage du track unique du single */}
              <div className="Modal-titre">
                <div className="track-list" key={selectedSingle.Track.number}>
                  <p id="Annee">{selectedSingle.Track.number} -</p>
                  <p>{selectedSingle.Track.title}</p>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SinglesSection;
